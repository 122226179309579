import React, { ChangeEvent, useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import InspectionRepair from "./components/InspectionRepair";
import CustomerProfile from "./components/CustomerProfile";
import customFetch from "../../API/CustomFetch";
import {
  customer_consent_valid_url,
  customer_consent_verify_otp,
} from "../../API/api";
import showToastMessage from "../../Toastify/Index";

const CustomerConsent = () => {
  const [validUrl, setValidUrl] = useState<Boolean>(false);
  const [otp, setOtp] = useState("");
  const [OTPVerified, SetOTPVerified] = useState(false);
  const [formType, setFormType] = useState("");
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const typeMasterId = urlParams.get("type");
    const vehicleProfileId = urlParams.get("vp");
    if (typeMasterId && parseInt(typeMasterId) === 53) {
      setFormType("Inspection Information");
    } else if (typeMasterId && parseInt(typeMasterId) === 54) {
      setFormType("Repair Criteria Information");
    }
    const url = `${customer_consent_valid_url}/${typeMasterId}/${vehicleProfileId}`;
    customFetch(url)
      .then((res) => {
        if (res.SUCCESS) {
          setValidUrl(true);
        }
        if (res.SUCCESS === false) {
          window.location.href = "https://greentiger.in";
          setValidUrl(false);
        }
      })
      .catch((err) => {
        window.location.href = "https://greentiger.in";
        setValidUrl(false);
      });
  }, [validUrl]);
  const handleOTP = (e: ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
  };

  const handleSubmit = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const typeMasterId = urlParams.get("type");
    const vehicleProfileId = urlParams.get("vp");
    const url = `${customer_consent_verify_otp}/${typeMasterId}/${vehicleProfileId}/${otp}`;
    customFetch(url, "", { method: "POST" }).then((res) => {
      // console.log(res)
      if (res?.SUCCESS) {
        showToastMessage(true, "OTP verification successful");
        SetOTPVerified(true);
      } else if (res?.SUCCESS === false) {
        showToastMessage(false, res?.MESSAGE);
      }
    });
  };
  useEffect(() => {
    // Add event listener to hide OTP input box when printing
    const beforePrint = () => {
      const otpInput = document.getElementById("otp-input");
      if (otpInput) {
        otpInput.style.display = "none";
      }
    };

    const afterPrint = () => {
      const otpInput = document.getElementById("otp-input");
      if (otpInput) {
        otpInput.style.display = "block";
      }
    };

    window.addEventListener("beforeprint", beforePrint);
    window.addEventListener("afterprint", afterPrint);

    return () => {
      window.removeEventListener("beforeprint", beforePrint);
      window.removeEventListener("afterprint", afterPrint);
    };
  }, []);
  return (
    <div className="flex-col justify-self-center" id="pdf-content">
      <Navbar />
      <h1 className="text-2xl font-bold mb-4 text-center">
        Customer Consent Form - {formType}
      </h1>
      <CustomerProfile />
      <InspectionRepair />
      {!OTPVerified && (
        <>
          <div className="text-center p-4 border-2" id="otp-input">
            <input
              className="border border-black px-2 py-2 text-center"
              type="text"
              value={otp}
              onChange={handleOTP}
              placeholder="Enter OTP"
            />
          </div>
          <div className=" text-center">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-8 rounded"
              onClick={handleSubmit}
            >
              Verify OTP to Agree
            </button>
          </div>
        </>
      )}
      <div className=" text-center">
        {OTPVerified && (
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-8 rounded"
            onClick={window.print}
          >
            Print
          </button>
        )}
      </div>
    </div>
  );
};

export default CustomerConsent;
