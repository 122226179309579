import React from "react";
import Modal from "react-modal";
import AgrementTextV1 from "./AgrementTextV1";
import { agreementData } from "../datatype";

Modal.setAppElement("#root");

interface TermsModalProps {
  isOpen: boolean;
  onAgree: () => void; // Add a new prop for handling agreement
  onClose: () => void;
  apiData: agreementData; // Allow dynamic content inside the modal
}

const TermsModal: React.FC<TermsModalProps> = ({
  isOpen,
  onAgree,
  onClose,
  apiData,
}) => {
  const handleAgreeClick = () => {
    onAgree(); // Call the onAgree callback from parent
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Terms and Conditions"
      className="modal"
      overlayClassName="overlay"
    >
      <div className="modal-content">
        <h2 className="text-xl px-8 mb-4 mx-8">Terms and Conditions</h2>
        {apiData?.AgreementVersion === "v1" ? (
          <AgrementTextV1 agrementInfo={apiData} />
        ) : (
          "Something went wrong or invalid request"
        )}
        {apiData?.AgreementVersion === "v1" && (
          <div className="flex justify-end">
            <button
              className="bg-blue-500 text-white px-6 mx-16 py-2 rounded hover:bg-blue-600"
              onClick={handleAgreeClick}
            >
              Agree
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TermsModal;
