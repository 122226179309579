import React, { ChangeEvent, useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import AgrementTextV1 from "./components/AgrementTextV1";
import UserProfile from "./components/UserProfile";
import TermsModal from "./components/TermsModal";
import {
  customer_agreement_details,
  customer_agreement_get_lead_profile,
  customer_agreement_verify_otp,
} from "../../API/api";
import customFetch from "../../API/CustomFetch";
import { agreementData } from "./datatype";
import showToastMessage from "../../Toastify/Index";

const Agreement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [OTPVerified, SetOTPVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [agreementData, setAgreementData] = useState<agreementData | null>();
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const leadId = urlParams.get("lead") ?? "";
    const agreementVersion = urlParams.get("ver");
    const url = `${customer_agreement_details}/${leadId}`;
    getAgreementDetails(url);
  }, []);
  const getAgreementDetails = (url: string) => {
    customFetch(url)
      .then((res) => {
        if (res?.SUCCESS === false) {
          showToastMessage(false, "You are not allowed to access this");
          setTimeout(() => {
            window.location.href = "https://greentiger.in";
          }, 1000);
        } else if (res?.SUCCESS) {
          setAgreementData(res.DATA[0]);
          if (res.DATA[0].Verified === 1) {
            SetOTPVerified(true);
          }
          showToastMessage(
            true,
            "Agreement Details fetched successfully",
            "success1"
          );
        }
      })
      .catch(() => {
        setAgreementData(null);
        showToastMessage(false, "You are not allowed to access this");
      });
  };
  const handleAgree = () => {
    setIsAgreed(true);
    handleCheckboxChange();
    console.log("Terms agreed");
  };

  const openModal = () => {
    setIsModalOpen(true);
  };
  const handleOTP = (e: ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the checkbox state
  };
  const handleSubmit = () => {
    if (isAgreed == false) {
      showToastMessage(
        false,
        "Please accept the terms and conditions and tick the check box to proceed further"
      );
      return;
    }
    if (otp == "") {
      showToastMessage(false, "Please enter OTP before submitting the form");
      return;
    }
    const urlParams = new URLSearchParams(window.location.search);
    const leadId = urlParams.get("lead") ?? "";
    const verifyURL = `${customer_agreement_verify_otp}/${leadId}/${otp}`;
    const body = JSON.stringify({ acceptedDate: new Date() });
    console.log(body);
    customFetch(verifyURL, body, { method: "POST" }).then((res) => {
      if (res?.SUCCESS) {
        console.log(res);
        showToastMessage(true, "OTP verification successful");
        SetOTPVerified(true);
      } else if (res?.SUCCESS === false) {
        showToastMessage(false, res?.MESSAGE);
      }
    });
  };
  return (
    <div className="flex-col justify-self-center">
      <Navbar />
      <h1 className="text-2xl font-bold mb-4 text-center">
        CONVERSION AGREEMENT
      </h1>
      {agreementData && !OTPVerified && (
        <UserProfile leadData={agreementData} />
      )}
      {!OTPVerified && (
        <form>
          {/* Other form fields */}
          <div className="mt-4 md:mx-16">
            {isAgreed && (
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-black-600"
                checked={isAgreed || isChecked}
                onChange={handleCheckboxChange}
              />
            )}

            {!isAgreed && (
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-black-600"
                checked={false}
                onChange={handleCheckboxChange}
              />
            )}
            <a
              href="#"
              onClick={openModal}
              className="text-black-500 underline mx-2"
            >
              I agree to the Terms and Conditions
            </a>
            <p>
              Please read the agreement terms and conditions carefully and click
              on agree button to move further.
            </p>
          </div>
          {agreementData && (
            <TermsModal
              isOpen={isModalOpen}
              onAgree={handleAgree}
              onClose={() => setIsModalOpen(false)}
              apiData={agreementData}
            />
          )}
        </form>
      )}
      {!OTPVerified && (
        <>
          <div className="text-center p-4 border-2 mx-16" id="otp-input">
            <input
              className="border border-black px-2 py-2 text-center"
              type="text"
              value={otp}
              onChange={handleOTP}
              placeholder="Enter OTP"
            />
          </div>
          <div className=" text-center">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-8 rounded"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </>
      )}
      <div className=" text-center my-4">
        {agreementData &&
          agreementData.AgreementVersion === "v1" &&
          OTPVerified && (
            <div className=" text-center p-2 mb-8 md:mx-16">
              <AgrementTextV1 agrementInfo={agreementData} />
            </div>
          )}
        {OTPVerified && (
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={window.print}
          >
            Print
          </button>
        )}
      </div>
    </div>
  );
};

export default Agreement;
