import React from "react";
import { agreementData } from "../datatype";
interface AgreementProps {
  agrementInfo: agreementData; // Allow dynamic content inside the modal
}
const AgrementTextV1: React.FC<AgreementProps> = (props) => {
  const agreementDate = props.agrementInfo.AgreementDate;
  // const AcceptedDate = props.agrementInfo?.AcceptedDate;
  const ageGroup = props.agrementInfo.AgeGroup;
  const age = getAgeFromAgeGroup(ageGroup);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  function getAgeFromAgeGroup(ageGroup: string): number | null {
    const birthDate = new Date(ageGroup);

    // Check if the date is invalid
    if (isNaN(birthDate.getTime())) {
      return null;
    }
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Adjust if the birth date hasn't occurred yet this year
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  function getDaySuffix(day: any) {
    if (day >= 11 && day <= 13) {
      return "th";
    }

    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }
  return (
    <div className="md:p-8 mb-8 mx-8 md:mx-16">
      {/* <p className="text-justify"> */}
      <p className="text-justify">
        This Conversion Agreement (“Agreement”) is entered into on this{" "}
        <strong>
          {`${new Date(agreementDate).getDate()}`}{" "}
          <sup>{getDaySuffix(new Date(agreementDate).getDate())}</sup> day of{" "}
          {`${monthNames[new Date(agreementDate).getMonth()]}`}{" "}
          {`${new Date(agreementDate).getFullYear()}`}
        </strong>{" "}
        {"("}
        <strong> “Effective Date”</strong>
        {")."}
      </p>
      <br />
      {"\n"}
      <p className="flex justify-start">
        <strong>BY AND BETWEEN</strong>
        <br />
      </p>
      <p className="mt-4 text-justify">
        <strong>Green Tiger Mobility Private Limited</strong>, a company
        incorporated under the Companies Act, 2013 vide CIN
        U50100KA2020PTC165210 and having its Principal Place of Business at
        62/1/15, Kodichikkanahalli Main Road, 2nd Floor, Bommanahalli Village,
        Begur Hubli, Bengaluru Karnataka, 58, through its Authorized Signatory,{" "}
        <strong>Ashish Dokania</strong> (hereinafter referred to as the{" "}
        <strong>“Company”</strong>), which expression shall, unless repugnant to
        the context, mean and include its heirs/
        successors/assignees/agents/representatives etc.
        <br />
      </p>
      <p className="flex justify-start my-4 ">
        <strong>AND</strong>
        <br />
      </p>
      <p className="text-justify">
        <strong>{props.agrementInfo?.LeadName}</strong>, an individual,{" "}
        {age && (
          <>
            aged <strong>{age} </strong>
          </>
        )}
        and PAN Number <strong>{props.agrementInfo?.PAN_No}</strong> and Aadhaar
        Card Number <strong>{props.agrementInfo?.Aadhar_No}</strong> and is a
        permanent resident of <strong>{props.agrementInfo?.Address}</strong>{" "}
        with vehicle number{" "}
        <strong>{props.agrementInfo?.RegistrationNo}</strong>
      </p>
      <br />
      <p className="text-justify">
        The <strong>“Company”</strong> and the <strong>“Client”</strong> are
        hereinafter individually referred to as “Party” and collectively as the
        “Parties”.{" "}
      </p>
      <p className="flex justify-start  my-4">
        <strong>WHEREAS</strong>,
      </p>
      <div className=" p-4 mb-8 mx-8">
        <ol className="list-item pl-8">
          <li className="relative text-justify">
            <span className="absolute left-0 -ml-6 font-bold">A.</span>
            The Company is in the business of developing solutions to make
            vehicles more environment-friendly as well as cost-effective,
            through the conversion of petrol two-wheeler vehicles into hybrid
            (bi-mode)/dual-powertrain two-wheeler vehicles, or addition of
            another powertrain to make the petrol two-wheeler vehicle into dual
            powertrain/ hybrid (bi-mode) two-wheeler vehicle by way of
            <strong> Retro-fitment</strong> (hereinafter referred to as “
            <strong>Conversion Solution</strong>" / “<strong>Conversion</strong>
            ”) to Clients across India.
          </li>
          <li className="relative text-justify">
            <span className="absolute left-0 -ml-6  font-bold">B.</span>
            The Client, post-registering his/her/its interest in availing the
            Conversion Solution, had delivered his/her/its petrol two-wheeler
            vehicle (“<strong>Subject Vehicle</strong>”) for Inspection to the
            Company.
          </li>
          <li className="relative text-justify">
            <span className="absolute left-0 -ml-6 font-bold">C.</span>
            The Company has inspected the overall condition of the Subject
            Vehicle, and upon finding it fit for the Dual PowerTrain/Hybrid
            (Bi-mode) Conversion Solution, had offered the Client the
            installation of the Conversion Solution, subject to full and
            complete payment of the fee (“<strong>Conversion Fee</strong>”).
          </li>
          <li className="relative text-justify">
            <span className="absolute left-0 -ml-6 font-bold">D.</span>
            Pursuant to the aforementioned offer by the Company, the Client has
            now expressed his/her/its willingness and readiness to install the
            Conversion Solution in the Subject-Vehicle, subject to the terms and
            conditions of this Agreement and the Motor Vehicle Act, 1988 (as
            amended from time to time) and the rules related thereto.
          </li>
          <li className="relative text-justify">
            <span className="absolute left-0 -ml-6 font-bold">E.</span>
            The Client has further represented to the Company that he/she/it is
            the lawful owner of the Subject Vehicle and has submitted to the
            Company the requisite documentation evidencing proof of ownership of
            the said Subject Vehicle.
          </li>
        </ol>
      </div>
      <p className="text-justify">
        <strong>NOW THEREFORE</strong>, in consideration of the mutual covenants
        and agreements set forth in this Agreement, and for other good and
        valuable consideration, the sufficiency of which is acknowledged by the
        Parties, the Parties, intending to be legally bound, covenant and agree
        as follows:{" "}
      </p>
      <div className="p-4 mb-8 mx-8">
        <ol className="list-item">
          <li className="mb-4 relative text-justify">
            <p className="text-justify">
              <strong>1. SCOPE OF THE AGREEMENT </strong>
            </p>
          </li>
          <ol className="list-item pl-4 text-justify">
            <li className="mb-4">
              <p className="text-justify">
                1.1. The Company hereby agrees to provide and install the
                Conversion Solution, by way of Retro-fitment, in the Subject
                Vehicle delivered by the Client and the related services
                associated thereto, as defined under Clause 6 of this Agreement
                (collectively referred to as “<strong>Services</strong>”), and
                the Client hereby agrees to avail the Services from the Company,
                as per the terms and conditions of this Agreement.
              </p>
            </li>
            <li className="mb-4">
              <p className="text-justify">
                1.2. The Company declares that the Subject Vehicle has been
                inspected by the personnel of the Company. Post thorough
                inspection of the overall condition of the Subject Vehicle, the
                Company has shared the inspection report with the Customer,
                declaring the successful completion of the inspection by the
                Company. Both Company and Customer believe that, as of the
                Effective Date of this Agreement and as conveyed by Inspection
                report, the Subject Vehicle is fit for installation of the
                Conversion Solution to the best of the knowledge of the Company
                and the Customer, and based on the assumption that−
              </p>
              <p className="text-justify ml-8">
                1.2.1.1. no part or component of the Subject Vehicle requires
                any repair or is damaged. In case any part requires any repair
                or is damaged, the same will be repaired on best effort basis,
                or as the case maybe, replaced with a new part or component, at
                the instance and cost of the Client The Client shall have the
                option to opt-out of the Conversion Solution, if in the opinion
                of the Client, the cost of such repair/replacement is high;
              </p>
              <p className="text-justify ml-8">
                1.2.1.2. no modification either illegal or unauthorized by the
                OEM has been made to the Subject Vehicle; and{" "}
              </p>
              <p className="text-justify ml-8">
                1.2.1.3. any and/or all parts or components of the Subject
                Vehicle are functioning in a proper manner to the best of their
                capability.
              </p>
            </li>
            <li className="mb-4">
              <p className="text-justify">
                1.3. The Client declares that it is satisfied with the
                Inspection of the Subject Vehicle conducted by the Company prior
                to the Effective Date of this Agreement, and grants the Company
                the right, power and privilege to convert the Subject Vehicle
                into hybrid (bi-mode)/dual powertrain vehicle by way of{" "}
                <strong>Retro-fitment</strong>
              </p>
            </li>
            <li className="mb-4">
              <p className="text-justify">
                1.4. The Client further declares that post-inspection of the
                Subject Vehicle, the Client has made no changes, alterations, or
                modifications to the Subject Vehicle or to any of its parts or
                components, unless the same had been suggested by the Company
                during the inspection of the Subject Vehicle, for the purpose of
                successful installation and implementation of a workable
                Conversion Solution prior to the Effective Date, and that any
                such change, alteration or modification is strictly as per the
                requirement communicated by the Company. The Company shall not
                be liable for any event, injury, occurrence, or damage, if any,
                caused to the Subject Vehicle, in the event it is found that the
                Client had made changes, alterations, or modifications to the
                Subject Vehicle that are contrary to the changes, alterations or
                modifications communicated by the Company.
              </p>
            </li>

            <li className="mb-4">
              <p className="text-justify">
                1.5. TThe intent of this Agreement is to describe the broad
                terms and conditions that Parties shall be obligated to perform,
                and be bound by, once the Client expresses his acceptance by
                placing his signature on the Agreement.
              </p>
            </li>
            <li className="mb-4">
              <p className="text-justify">
                1.6. Upon placing the signature on this Agreement, the Client
                shall get a One-Time Password (“OTP”) on Client’s registered
                mobile number, which the Client is required to share with the
                authorized personnel of the Company, for the purpose of
                authentication. The successful authentication shall deem to mean
                that the Subject Vehicle has been delivered to, and accepted by,
                the Company for the purpose of installation of a workable
                Conversion Solution, as per the terms and conditions of this
                Agreement.
              </p>
            </li>
          </ol>
          <li className="mb-4 relative">
            <p className="text-justify">
              <strong>2. CONVERSION FEE </strong>
            </p>
            <ol className="list-item pl-4">
              <li className="mb-4">
                <p className="text-justify">
                  2.1. As of the Effective Date of this Agreement, the Client
                  shall pay to the Company, the total amount as computed and
                  communicated to the Client by the Company through its Tax
                  Invoice, for the Services to be rendered by the Company, which
                  shall include the cost of the parts/components added to the
                  Subject Vehicle, labor, and other expenses incurred in
                  connection with the conversion (“
                  <strong>Conversion Fee</strong>”).
                </p>
              </li>
              <li className="mb-4">
                <p className="text-justify">
                  2.2. The Parties hereby acknowledge and agree that the
                  Pre-Order Registration Fee shall be adjusted against the
                  Conversion Fee.
                </p>
              </li>
              <li className="mb-4">
                <p className="text-justify">
                  2.3. The Client hereby agrees and acknowledges that only upon
                  receiving the full and complete Conversion Fee as mentioned
                  under Clause 2.1., the Company will render its Services to the
                  Client.{" "}
                </p>
              </li>
              <li className="mb-4">
                <p className="text-justify">
                  2.4. In the event, the Client fails to pay the Conversion Fee
                  to the Company on the Effective Date of this Agreement, it
                  shall be deemed to mean that the Client is not willing to
                  permit the Company to install the Conversion Solution in the
                  Subject Vehicle and the Pre-Order Registration Fee paid by the
                  Client shall be forfeited in such case.{" "}
                </p>
              </li>
              <li className="mb-4">
                <p className="text-justify">
                  2.5. The Company disclaims all responsibilities and
                  liabilities to render any Post-Inspection Services to the
                  Client without having received full and complete payment as
                  mentioned under Clause 2.1. of this Agreement.{" "}
                </p>
              </li>
            </ol>
          </li>
          <li className="mb-4 relative">
            <p className="text-justify">
              <strong>3. RETRO-FITMENT </strong>
            </p>
            <ol className="list-item pl-4">
              <li className="mb-4">
                <p className="text-justify">
                  3.1. The Client hereby understands and agrees that the Company
                  shall conduct a Retro-fitment process for the purpose of
                  converting the two-wheeler petrol driven vehicle into hybrid
                  (bi-mode)/dual-powertrain vehicle, which shall involve
                  multiple changes including but not limited to change in
                  throttle, wire harness, addition of mode change-over switch,
                  addition of motor with coupling plate, modification in the
                  structure of the vehicle including the boot space and front
                  panel to accommodate display. The stated changes will increase
                  the wight of the vehicle which may impact the fuel efficiency
                  to some extent on petrol mode, etc. The Company disclaims all
                  liabilities, responsibilities, and warranties with respect to
                  such changes as mentioned hereunder this Clause. The Client
                  hereby agrees and acknowledges that the installation of the
                  Conversion Solution may decrease the fuel efficiency of the
                  converted vehicle and may impact the riding condition of the
                  vehicle, and that the Client shall not hold or be entitled to
                  hold the Company responsible for any such changes. The Client
                  hereby gives full and free consent to the Company to make any
                  such changes as may be necessitated for the purpose of
                  installation of the Conversion Solution pursuant to this
                  Agreement.{" "}
                </p>
              </li>
              <li className="mb-4">
                <p className="text-justify">
                  3.2. The Client hereby understands and acknowledges that the
                  boot space of the Subject Vehicle will be used and adjusted to
                  store the battery mount, such that the boot may not be
                  available to the Client for storage purposes. The Client
                  hereby gives full and free consent to the Company for said
                  usage and adjustment of the boot space as mentioned hereunder
                  this clause.{" "}
                </p>
              </li>
              <li className="mb-4">
                <p className="text-justify">
                  3.3. The Client understands and acknowledges that the battery
                  performance of the converted vehicle may vary for the rider to
                  rider as per the general upkeep of the said vehicle and the
                  Company disclaims any and/or all liabilities, responsibilities
                  and warranties, whether express or implied, for the same,
                  including any warranty with respect to the mileage of the said
                  converted vehicle.{" "}
                </p>
              </li>
              <li className="mb-4">
                <p className="text-justify">
                  3.4. The Client permits the Company to fit and place the
                  Lithium Battery Pack in the Boot, which has been tested and
                  approved by ARAI for such application and such placements.
                  However, it shall be the sole responsibility and liability of
                  the Client to ensure proper maintenance and upkeep of the
                  converted vehicle. The Company shall not be liable and
                  responsible, whether expressly or impliedly, for any injury,
                  damage, or harm caused to the converted vehicle due to any act
                  or omission on the part of the Client.{" "}
                </p>
              </li>
              <li className="mb-4">
                <p className="text-justify">
                  3.5. The Client agrees and acknowledges that he/she/it has
                  read through and understood the “Terms of use” displayed on
                  the Company’s Platform (website, mobile application and any
                  other digital application) and the Client, by way of placing
                  his/her/its signature on this Agreement, confirms the
                  acceptance of the same.{" "}
                </p>
              </li>
            </ol>
          </li>
          <li className="mb-4 relative">
            <p className="text-justify">
              <strong>4. POST-CONVERSION TESTING AND QUALITY CHECK </strong>
            </p>
            <ol className="list-item pl-4">
              <li className="mb-4">
                <p className="text-justify">
                  4.1. The Client hereby permits and allows the Company to test
                  as per the Company’s requirements, the converted vehicle on
                  several parameters, including carrying out certain performance
                  checks (including electric functions) and data checks, to
                  ensure the converted vehicle is safe and roadworthy as to the
                  satisfaction of the Company. The Company, however, makes no
                  representation and warranty as to the accuracy, performance,
                  and completeness of such checks, and that any damage, injury
                  or harm caused to the converted vehicle post-conversion
                  testing and delivery of the converted vehicle to the Client,
                  shall be the sole responsibility and liability of the Client.
                  The Company shall not be liable and responsible in any manner
                  whatsoever and howsoever, and makes no representation or
                  warranty, whether express or implied, for any such damage or
                  injury caused to the converted vehicle post its delivery to
                  the Client, due to any act or omission on part of the Client.
                </p>
              </li>
            </ol>
          </li>
          <li className="mb-4 relative">
            <p className="text-justify">
              <strong>5. TIME FOR THE PROCESS </strong>
            </p>
            <ol className="list-item pl-4">
              <li className="mb-4">
                <p className="text-justify">
                  5.1. he Company agrees to use its best efforts to complete the
                  Conversion within 5 days after the Effective Date of this
                  Agreement, subject to any external factor beyond the control
                  of the Company.
                </p>
              </li>
              <li className="mb-4">
                <p className="text-justify">
                  5.2. However, the Company makes no representations or
                  warranties as to the actual completion date of the Conversion,
                  including the Retro-Fitment process and Post-Conversion
                  Testing and Quality Check as aforementioned under Clause 3 and
                  Clause 4 of this Agreement.
                </p>
              </li>
            </ol>
          </li>
          <li className="mb-4 relative">
            <p className="text-justify">
              <strong>6. OTHER SERVICES </strong>
            </p>
            <ol className="list-item pl-4">
              <li className="mb-4">
                <p className="text-justify">
                  6.1.{" "}
                  <strong>
                    POST-CONVERSION DELIVERY OF THE SUBJECT VEHICLE
                  </strong>
                </p>
                <p className="text-justify ml-8">
                  6.1.1. The Company shall notify the Client of the completion
                  of the Conversion of the Subject Vehicle, once the Company is
                  satisfied with the safety and roadworthiness of the said
                  Subject Vehicle, post-conversion. It shall be the duty and
                  obligation of the Client to pick up the converted Subject
                  Vehicle from the designated service/ experience center of the
                  Company as intimated by the Company to the Client. The Company
                  shall not offer door-step delivery of the converted vehicle to
                  the Client.
                </p>
                <p className="text-justify ml-8">
                  6.1.2. In the event, the Client desires any door-step delivery
                  of the converted vehicle, the Client agrees to pay to the
                  Company additional charges for the said delivery, which shall
                  be based on the delivery location of the Client and include
                  the cost of the labor.
                </p>
              </li>
              <li className="mb-4">
                <p className="text-justify">
                  6.2.{" "}
                  <strong>
                    UPDATION OF THE REGISTRATION CERTIFICATE (“RC”)
                  </strong>
                </p>
                <p className="text-justify ml-8">
                  6.2.1. The Company shall assist the Client in the updation of
                  the Registration Certificate (“RC”) with respect to the
                  converted vehicle. The said updation process generally takes
                  15 (“Fifteen”) days to complete, however, the same may be
                  increased subject to the working of the concerned authority at
                  the Regional Transport Office.
                </p>
                <p className="text-justify ml-8">
                  6.2.2. The Company disclaims all liabilities, responsibilities
                  and/or warranties, whether express or implied, with respect to
                  the updation of the RC Book, and the time taken for the same,
                  in case the concerned authority at the Regional Transport
                  Office delays/postpones the updation process.
                </p>
                <p className="text-justify ml-8">
                  6.1.3. The Client hereby understands that once the application
                  for updating of the RC has been filed by the Client and is
                  acknowledged by the Regional Transport Authority, the client
                  will proceed as per RTO requirements.{" "}
                </p>
              </li>
            </ol>
          </li>
          <li className="mb-4 relative">
            <p className="text-justify">
              <strong>7. OWNERSHIP </strong>
            </p>
            <ol className="list-item pl-4">
              <li className="mb-4">
                <p className="text-justify">
                  7.1. Upon completion of the Conversion, the ownership of the
                  dual powertrain/hybrid (bi-mode) electric vehicle shall remain
                  with the Client. It is hereby agreed between the Parties that
                  the Client, by virtue of delivering the Subject Vehicle to the
                  Company, does not convey any right, title, or interest in the
                  Subject Vehicle to the Company. The possession of the Subject
                  Vehicle is transferred to the Company solely for the purpose
                  of installation of the Conversion Solution, and the said
                  possession shall be transferred to the Client upon the
                  completion of the said conversion, post-conversion testing,
                  quality checks undertaken by the Company, as to the
                  satisfaction of the Company and payment of the conversion
                  charges by the Client to the Company.
                </p>
              </li>
            </ol>
          </li>
          <li className="mb-4 relative">
            <p className="text-justify">
              <strong>8. WARRANTY </strong>
            </p>
            <ol className="list-item pl-4">
              <li className="mb-4">
                <p className="text-justify">
                  8.1. The Company warrants that the Conversion and the related
                  Services will be performed in a professional and workmanlike
                  manner and that all parts/components used in the Conversion
                  will be new and of good quality. The Company further warrants
                  that the dual powertrain/hybrid (bi-mode) electric vehicle
                  will be free from defects in materials and workmanship for a
                  period of 6 months from the date of completion of the
                  Conversion (“Warranty Period”), and subject to the Warranty
                  Policy of the Company.
                </p>
              </li>
              <li className="mb-4">
                <p className="text-justify">
                  8.2. If during the Warranty Period, the Client experiences any
                  defects in materials or workmanship, the Company shall, at its
                  option, repair or replace the defective part or component at
                  no cost to the Client.
                </p>
              </li>
              <li className="mb-4">
                <p className="text-justify">
                  8.3. Notwithstanding the aforementioned, the Company disclaims
                  all its liabilities, responsibilities and/or makes no
                  warranty, whether express or implied, for any event, act,
                  omission, or conduct on part of the Client, that has caused
                  damage or injury to the converted vehicle, post successful
                  delivery by the Company, including but not limited to, any
                  scheduled maintenance service, wear-and-tear items like wheel
                  wobbling due to wear and tear of tyres, forks, etc., or any
                  failure caused by abuse or improper maintenance of the
                  converted vehicle by the Client.{" "}
                </p>
              </li>
            </ol>
          </li>
          <li className="mb-4 relative">
            <p className="text-justify">
              <strong>9. COMPLIANCE WITH APPLICABLE LAWS </strong>
            </p>
            <ol className="list-item pl-4">
              <li className="mb-4">
                <p className="text-justify">
                  9.1. The Company gives utmost regard to the safety of its
                  ClienThe Company gives utmost regard to the safety of its
                  Clients, and therefore, shall carry out all statutorily
                  mandated testing before delivery of the converted vehicle to
                  the Client.{" "}
                </p>
              </li>
              <li className="mb-4">
                <p className="text-justify">
                  9.2. The Company will carry out conversion in accordance with
                  the testing certification issued by Automotive Research
                  Association of India (ARAI) under Automotive Industry
                  Standards AIS 123 (part 1) published by the Automotive
                  Research Association of India (ARAI) on behalf of the
                  Automotive Industry Standard Committee under the Central Motor
                  Vehicle Rules (CMVR)− Technical Committee, 2013 (as amended
                  from time to time) by the Ministry of Road Transport &
                  Highways, Department of Road Transport & Highways, Government
                  of India.{" "}
                </p>
              </li>
              <li className="mb-4">
                <p className="text-justify">
                  9.3. As part of the safety testing procedure, the Company
                  shall have the right to verify that the converted vehicle is
                  in compliance with all the applicable standards and laws, and
                  is worthy of being safely driven on roads.
                </p>
              </li>

              <li className="mb-4">
                <p className="text-justify">
                  9.4. Further, pursuant to the Automotive Industry Standards
                  ASI-123 and other standards published by the Automotive
                  Research Association of India (ARAI) and the related rules
                  thereto, the Company is entitled to request and avail from the
                  Client, and the Client shall cooperate with the Company in
                  provisioning of, any information the Company may need for
                  Conformity of Production (“CoP”) testing. The Company shall
                  further be entitled to collect, transmit and receive real-time
                  data related to the converted Vehicle diagnostics and
                  maintenance, navigation, safety, and connectivity, using
                  telematics (sensors, GPS, cellular networks, etc.) for
                  process/testing and other improvements.
                </p>
              </li>
            </ol>
          </li>
          <li className="mb-4 relative">
            <p className="text-justify">
              <strong>10. LIMITATION OF LIABILITY </strong>
            </p>
            <ol className="list-item pl-4">
              <li className="mb-4">
                <p className="text-justify">
                  10.1. The Company’s liability to the Client for any and/or all
                  claims arising out of or relating to this Agreement or the
                  Services shall be limited to the amount paid by the Client to
                  the Company for the Services.
                </p>
              </li>
            </ol>
          </li>
          <li className="mb-4 relative">
            <p className="text-justify">
              <strong>11. EXCLUSION OF WARRANTIES </strong>
            </p>
            <ol className="list-item pl-4">
              <li className="mb-4">
                <p className="text-justify">
                  11.1. Post-Conversion of the Vehicle, if the Client gets any
                  work done on the said Vehicle, including any modification or
                  servicing of the said Vehicle from any third party other than
                  the Company, and during such work, modification, or servicing
                  if such third-party interferes with the Conversion Solution
                  which causes any malfunctioning, hazard, or
                  performance-related issues, then in such case, the Company
                  shall not be liable in any manner whatsoever, including for
                  any losses incurred by the Client due to such event. The
                  Client waives its right to pursue any legal action against the
                  Company in any such case as stated hereunder this Clause. 
                </p>
              </li>
              <li className="mb-4">
                <p className="text-justify">
                  11.2. Post-Conversion of the Vehicle, the Client hereby agrees
                  to abide by and follow, at all times, the instructions
                  provided under the user manual consisting of all the SOPs for
                  washing, charging batteries, removing and installing
                  batteries, repair, and other acts related thereto. If the
                  Client fails to abide by and follow any instruction provided
                  under such SOPs and that non-compliance leads to any
                  malfunctioning of the Conversion Solution, then in such case,
                  the Company shall not be liable in any manner whatsoever,
                  including for any losses incurred by the Client due to such
                  event. The Client waives its right to pursue any legal action
                  against the Company in any such case as stated hereunder this
                  Clause. 
                </p>
              </li>
            </ol>
          </li>
          <li className="mb-4 relative">
            <p className="text-justify">
              <strong>12. FORCE MAJEURE </strong>
            </p>
            <ol className="list-item pl-4">
              <li className="mb-4">
                <p className="text-justify">
                  12.1. The Company shall have the full and absolute right and
                  authority to make modifications to the booking sequence in the
                  event of oversubscription, technological shortcomings,
                  economic or political crisis, and international/national
                  resource crisis or Pandemic, or any other event or cause, or
                  natural disaster, which is beyond the control of the Company
                  (“Force Majeure Event”).
                </p>
              </li>
              <li className="mb-4">
                <p className="text-justify">
                  12.2. TThe Client shall not hold the Company liable and/or
                  responsible for non-performance or delay in performing its
                  obligations under this Agreement due to occurrence of any
                  Force Majeure Event.
                </p>
              </li>
            </ol>
          </li>
          <li className="mb-4 relative">
            <p className="text-justify">
              <strong>13. GOVERNING LAW AND JURISDICTION </strong>
            </p>

            <p className="text-justify mb-4 pl-4">
              This Agreement shall be governed by and construed in accordance
              with the laws of India. The courts at Bangalore, Karnataka shall
              have the exclusive jurisdiction with regard to the subject-matter
              of this Agreement.
            </p>
          </li>
          <li className="mb-4 relative">
            <p className="text-justify">
              <strong>14. NO AMENDMENT; STANDARD CONTRACT </strong>
            </p>

            <p className="text-justify mb-4 pl-4">
              This Agreement is a standard contract and the Client shall have no
              right to, or demand any, amendment, or modification to any of the
              terms of this Agreement. By placing signature on this Agreement,
              the Client accepts the terms of this Agreement “AS IS”.
            </p>
          </li>
          <li className="mb-4 relative">
            <p className="text-justify">
              <strong>15. ENTIRE AGREEMENT </strong>
            </p>

            <p className="text-justify mb-4 pl-4">
              This Agreement constitutes the entire agreement between the
              Parties and supersedes all prior negotiations, understandings, and
              agreements between them relating to the subject matter of this
              Agreement.
            </p>
          </li>
        </ol>
      </div>
      <p className="text-justify">
        <strong>IN WITNESS WHEREOF</strong>, the Parties have executed this
        Agreement by their duly authorized officers to be effective as of the
        Effective Date.
      </p>
      <br />
      <div className="flex flex-row">
        <div className="w-[60%] text-justify">
          <p>
            For <span className="font-medium">Company</span>
          </p>
          <br />
          <br />
          <p className="font-medium">Authorized Signatory</p>
          <p className="font-medium">Name: Yatish Kumar</p>
          <p className="font-medium">Designation: Sales Head</p>
          <p className="font-medium">Date:</p>
          {/* <p className="font-medium">
            Date: {`${new Date(AcceptedDate).toLocaleDateString("en-IN")}`}
          </p> */}
        </div>
        <div>
          <div className="text-justify">
            <p>
              For <span className="font-medium">Client</span>
            </p>
            <br />
            <br />
            <p className="font-medium">Authorized Signatory</p>
            <p className="font-medium">Name: {props.agrementInfo?.LeadName}</p>
            <p className="font-medium">Designation: </p>
            <p className="font-medium">Date:</p>
            {/* <p className="font-medium">
              Date: {`${new Date(AcceptedDate).toLocaleDateString("en-IN")}`}
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgrementTextV1;
